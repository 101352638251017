<si-landing-page
  [heading]="'Software Manager'"
  [subtitle]="''"
  [links]="footerLinks"
  backgroundImageUrl="/assets/images/KeyVisual-Software-Manager-1920x1080.original.svg"
  [copyrightDetails]="copyrightDetails"
  [version]="version"
>
  <button type="submit" class="btn btn-primary mt-8 w-100"  (click)="loginWithRedirect()" >
   Log in
  </button>
  <br />
  <br />
  <div>
   <span class="si-body-2" style="color: azure;">Don't have an account? </span> <a (click)="signupWithRedirect()" class="si-title-2 ms-6" style="cursor:pointer;">Register now</a>
  </div>
  <div *ngIf="loading" class="spinner-wrapper">
    <si-loading-spinner></si-loading-spinner>
  </div>
</si-landing-page>
