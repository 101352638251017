import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from '../../../environments/environment';
import { FooterLinksService } from '../../services/footerLinks.service';
import { CopyrightDetails } from '@simpl/element-ng';
import { Subject, takeUntil } from 'rxjs';
import { ConfigService } from 'src/app/services/config.service';


@Component({
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})

export class LandingComponent{
    private destroy$ = new Subject<void>();
    loading: boolean = false;
    footerLinks: {title: string, href:string}[]=[];
    copyrightDetails: CopyrightDetails | undefined;
    version: string = '';
    constructor(public auth: AuthService,private footerLinksService: FooterLinksService, private config: ConfigService) {
      if(environment.name !== 'local'){
      this.config.getApiUrl().subscribe((uri)=>{
        environment.apiRoot = uri.apiBaseUrl;
        environment.publicBlobBaseUrl =  uri.blobURL + '$web/';
        environment.sasToken =  uri.sasToken ;
        this.footerLinksService.getLandingFooterLink().pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          const fileredData = data.filter(
            (x) => x.name !== 'Release Notes' && x.name !== 'Third Party Terms' && x.name !== 'Terms'
          );
          this.footerLinks = fileredData.map((x) => {
            if(x.name === 'Copyrightyear'){
              this.copyrightDetails = {
               company:'Siemens',
               startYear: x.value,
              }
            }
            else if(x.name === 'Version'){
               this.version = x.value;
            }
            else{
            return { title: x.name, href: x.url };
            }
            return { title: '', href: '' };
          });
        });
      })
    }
      
     }
    
    loginWithRedirect(): void {
      this.loading = true;
      // Call this to redirect the user to the login page
      this.auth.loginWithRedirect({
        appState: {
          target: 'main'
        },
        authorizationParams: { redirect_uri: environment.auth.redirectUri }
      });
      this.loading = false;
    }

    signupWithRedirect(): void {
      // Call this to redirect the user to the login page
    window.location.href = environment.signUpUrl;
    }
  }
  
