export const environment = {
  production: true,
  name: 'prod',

  siemensId: {
    domain: 'siemens-00255.siemens.auth0app.com',
    clientId: 'WwkD8bpXdyKbpETJyNLBh3h7IN1czVQF',
    audience: 'https://softwaremanager.api.siemens.com'
  },
  apiRoot: 'https://software-manager.siemens.com/api/v1/',
  auth: { redirectUri:'https://software-manager.siemens.com'} ,
  keyLight:{
    url:'https://subscribe.siemens.com/'
  },
  appInsights: {
    instrumentationKey: 'f4bc7d62-bcd9-4a87-bb5a-4f123937e34c',
    applicationName: 'Software Manager'
  },
  publicBlobBaseUrl: '',
  sasToken: '',
  logoutUrl:"https://login.siemens.com/v2/logout?returnTo=https://siemens-00255.siemens.auth0app.com/v2/logout?client_id=WwkD8bpXdyKbpETJyNLBh3h7IN1czVQF%26returnTo=https://software-manager.siemens.com"
,
signUpUrl: 'https://siemens-00255.siemens.auth0app.com/authorize?connection=main-tenant-oidc&scope=openid&response_type=code&client_id=WwkD8bpXdyKbpETJyNLBh3h7IN1czVQF&redirect_uri=https://software-manager.siemens.com&display=sup-active|apple-off|twitter-off|github-off|facebook-off'
};
